<template>
  <div id="topicresource">
    <div class="left">
      <div class="title">
        <span>+</span>
        <b>添加授课过程</b>
      </div>
      <ul class="classList">
        <li v-for="(item, index) in list" :key="index">
          <div class="head">
            <img
              class="line"
              src="../../img/板块图标 - 副本_slices/矢量智能对象(3).png"
              alt=""
            />
            <span>{{ item.catalog_name }}</span>
            <el-dropdown
              trigger="click"
              placement="bottom-start"
              style="height: 50/96in; display: flex"
            >
              <span
                class="el-dropdown-link"
                style="display: flex; align-items: center"
                ><img
                  class="more"
                  src="../../img/板块图标 - 副本_slices/更  多 (1) 拷贝 6.png"
                  style="width: 0.2rem"
                  alt=""
              /></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  >编辑课程<img
                    src="../../img/板块图标 - 副本_slices/编辑.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
                <el-dropdown-item
                  >复制课程<img
                    src="../../img/板块图标 - 副本_slices/复制.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
                <el-dropdown-item
                  >删除课程<img
                    src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <ul
            class="smallList"
            v-for="(item1, index1) in item.catalog"
            :key="index1"
          >
            <li
              @click="num = index + String(index1)"
              :class="{ active: num == index + String(index1) }"
            >
              <img
                class="left_img"
                src="../../img/板块图标 - 副本_slices/矢量智能对象.png"
                alt=""
              />
              <span>{{ item1.catalog_name }}</span>
              <div class="right_img">
                <img
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(1).png"
                  alt=""
                />
                <img
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(2).png"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <main>
      <header>
        <ul>
          <li
            @click="
              three = 0;
              totopicLocal();
            "
            :class="{ active: three == 0 }"
          >
            本地上传
          </li>
          <li
            @click="
              three = 1;
              totopicPersonal();
            "
            :class="{ active: three == 1 }"
          >
            个人资源导入
          </li>
          <li
            @click="
              three = 2;
              totopicSystem();
            "
            :class="{ active: three == 2 }"
          >
            系统资源导入
          </li>
        </ul>
      </header>
      <div class="main">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      num: 0,
      three: 0,
      list: "",
      csid: this.$route.query.csid,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      util.post("/catalog", { csid: this.$route.query.csid }, (res) => {
        console.log(res.data);
        this.list = res.data;
        this.csid = this.$route.query.csid;
        this.totopicLocal();
      });
    },
    totopicLocal() {
      this.$router.push({
        path: "/main/addscene/topicresource/topiclocal",
        query: {
          csid: this.csid,
          dataid: this.$route.query.dataid,
          filetype: this.$route.query.filetype,
          edit: this.$route.query.edit,
        },
      });
    },
    totopicPersonal() {
      this.$router.push({
        path: "/main/addscene/topicresource/topicpersonal",
        query: {
          csid: this.csid,
          dataid: this.$route.query.dataid,
          filetype: this.$route.query.filetype,
        },
      });
    },
    totopicSystem() {
      this.$router.push({
        path: "/main/addscene/topicresource/topicsystem",
        query: {
          csid: this.csid,
          dataid: this.$route.query.dataid,
          filetype: this.$route.query.filetype,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
#topicresource {
  display: flex;
  height: 100%;
  .left {
    width: 2.38rem /* 238/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;
    .title {
      height: 0.44rem /* 44/100 */;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      background-color: #e5e7ea;
      span {
        display: inline-block;
        width: 16/96in;
        height: 16/96in;
        background-color: #1c5eff;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 16/96in;
      }
      b {
        font-size: 0.14rem /* 14/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .classList {
      font-size: 0.14rem /* 14/100 */;
      font-weight: 500;
      color: #000000;
      line-height: 0.63rem /* 63/100 */;
      .head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.17rem /* 17/100 */;
        cursor: pointer;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          color: #131313;
          font-size: 18px;
          font-weight: bold;
        }
        .line {
          width: 18px;
          margin: 0 7px 0 8px;
        }
      }
      .smallList {
        .active {
          font-weight: bold;
        }
        li:hover {
          font-weight: bold;
        }
        li {
          display: flex;
          align-items: center;
          padding: 0 15px 0 33px;
          line-height: 40px;
          cursor: pointer;
          .left_img {
            width: 16px;
            margin-right: 6px;
          }
          span {
            width: 100%;
            font-size: 14px;
            color: #131313;
          }
          .right_img {
            // width: 36/96in;
            display: flex;
            img:first-child {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
  main {
    width: 100%;
    height: 100%;
    header {
      border-bottom: 1/96in solid #cdcdcd;
      padding-left: 57px;
      ul {
        display: flex;
        height: 43/96in;
        li {
          display: flex;
          align-items: center;
          margin-right: 73px;
          color: #131313;
          font-size: 16px;
          height: 100%;
          border-bottom: 3px solid transparent;
          cursor: pointer;
        }
        .active {
          color: #1c5eff;
          border-bottom: 3px solid #1c5eff;
        }
      }
    }
    .main {
      width: 100%;
      height: calc(100% - 44px);
      overflow: auto;
      background-color: #fff;
    }
    .main::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>